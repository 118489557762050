import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import TopNavBar from '../components/TopNavBar'
import CoverImage from '../components/CoverImage'

import PostTemplateDetails from '../components/PostTemplateDetails'

class PostTemplate extends React.Component {
  render() {
    const { title, subtitle, author, url} = this.props.data.site.siteMetadata
    const post = this.props.data.markdownRemark
    const { title: postTitle, description: postDescription } = post.frontmatter
    const description = postDescription !== null ? postDescription : subtitle
    const navBar = <TopNavBar data={this.props.data} />
    const coverImage = <CoverImage page={post} />
    const imageUrl = `${url}${post.frontmatter.cover_image.publicURL}`
    return (
      <Layout navBar={navBar} coverImage={coverImage}>
        <div>
          <Helmet>
            <title>{`${postTitle}`}</title>
            <meta name="description" content={description} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={author.name} />
            <meta name="twitter:creator:id" content={author.twitter} />
            <meta name="twitter:title" content={postTitle} />
            <meta name="twitter:image:alt" content={post.frontmatter.cover_image_title} />

            {post.frontmatter.cover_image && <meta name="twitter:image" content={imageUrl} /> }
            <meta name="og:description" content={description} />
            <meta name="og:type" content="website" />
            <meta name="og:title" content={postTitle} />
          </Helmet>
          <PostTemplateDetails {...this.props} />
        </div>
      </Layout>
    )
  }
}

export default PostTemplate

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        author {
          name
          twitter
          github
          rss
        }
        disqusShortname
        url
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        tagSlugs
      }
      frontmatter {
        title
        tags
        date
        description
        cover_image {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1800) {
              base64
              tracedSVG
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
              presentationWidth
              presentationHeight
            }
          }
        }
        cover_image_title
        cover_image_url
      }
    }
  }
`
